<div class="container">
    <div class="row filter-block mb-3">
        <div class="col-12">
            <div class="row mb-2">
                <div class="col-3">
                    <Input
                        :label="'Название Контрагента'"
                        :placeholder="'Введите название'"
                        v-on:change="selectPartnerName"
                    />
                </div>
                <div class="col-3">
                    <label>Филиал</label>
                    <Multiselect
                        v-model="selectionValues.branch_codes"
                        :options="branches"
                        :label="'full_name'"
                        placeholder="Выбор филиала"
                        valueProp="Ref_Key"
                        trackBy="full_name"
                        mode="tags"
                        :searchable="true"
                        @change="selectBranch"
                    />
                </div>
                <div class="col-3">
                    <label>Статус</label>
                    <Multiselect
                        v-model="selectionValues.statuses"
                        :options="statusFilters.statuses"
                        :label="'name'"
                        valueProp="id"
                        mode="tags"
                        trackBy="name"
                        :placeholder="'Статус'"
                        :searchable="true"
                        @change="selectStatus"

                    />
                </div>
                <div class="col-3">
                    <label>Оферта</label>
                    <select
                        @input="selectOffer"
                    >
                        <option disabled selected value="">Выбрать оферту</option>
                        <option value="0">Не отправлена</option>
                        <option value="1">Отправлена</option>
                    </select>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3">
                    <Input
                        :label="'Код Контрагента'"
                        :placeholder="'Введите код контрагента'"
                        v-on:change="selectPartnerCode"
                    />
                </div>
                <div class="col-3">
                    <Input
                        :label="'Район Контрагента'"
                        :placeholder="'Введите название района'"
                        v-on:change="selectArea"
                    />

                </div>
                <div class="col-3">
                    <label>Статус закрытия</label>
                    <Multiselect
                        v-model="selectionValues.close_statuses"
                        :options="statusFilters.closeStatuses"
                        :label="'name'"
                        valueProp="id"
                        mode="tags"
                        trackBy="name"
                        :placeholder="'Статус закрытия'"
                        :searchable="true"
                        @change="selectCloseStatus"

                    />
                </div>
                <div class="col-3">
                    <label>Опросник</label>
                    <select
                        @input="selectQuest"
                    >
                        <option disabled selected value="">Опросник</option>
                        <option value="0">Не пройден</option>
                        <option value="1">Пройден</option>
                    </select>
                </div>
            </div>
            <div class="row" v-if="role === 1">
                <div class="col-3">
                    <label>Менеджер</label>
                    <Multiselect
                        mode="single"
                        v-model="selectionValues.manager_id"
                        :options="managers"
                        :label="'username'"
                        valueProp="id"
                        trackBy="username"
                        placeholder="Выбор менеджера"
                        :searchable="true"
                        @change="selectManager"
                    />
                </div>
                <div class="col-3">
                    <label>Законтрактован</label>
                    <select
                        @input="selectContract"
                    >
                        <option disabled selected value="">Законтрактован</option>
                        <option value="0">Нет</option>
                        <option value="1">Да</option>
                    </select>
                </div>
                <div class="col-3">
                    <div class="row">
                        <div class="col-6">
                            <label>Дата создания с:</label>
                            <datepicker
                                v-model="date_from"
                                class="picker"
                                placeholder="Дата от"
                            />
                        </div>
                        <div class="col-6">
                            <label>Дата создания по:</label>
                            <datepicker
                                v-model="date_to"
                                placeholder="Дата до"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="row">
                        <div class="col-8">
                            <label>Оферта прочитана</label>
                            <select
                                @input="selectOfferRead"
                            >
                                <option disabled selected value="">Выбрать тип</option>
                                <option value="0">Не прочитана</option>
                                <option value="1">Прочитана</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-3">
        <transition-group name="flip-list">
            <div v-for="(partner, ind) in partnersList" :key="partner.external_id" @click='showHideOrders(ind)'>
                <div class="row partners-row">
                    <div class="col-5 mt-2 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <span>
                                    {{ partner.name }}
                                </span>
                                (<span class="partner-code">
                                    {{ partner.external_id }}
                                </span>)
                            </div>
                        </div>
                    </div>
                    <div class="col-5 mt-2 mb-2">
                       <span>
                           {{ partner.area }}
                       </span>
                    </div>
                    <div class="col-1 mt-2 mb-2">
                        <span class="help-span">
                           Заказы :
                       </span>
                        <span>
                           {{ partner.count_orders }} шт.
                       </span>
                    </div>
                    <div class="col-1 mt-2 mb-2">
                        <span>
                            <span class="icon arrow-down"></span>
                       </span>
                    </div>
                </div>
                <div v-for="order in partner.orders">
                    <div class="row orders-row" v-show="showOrdersArray.includes(ind)">
                        <div class="col-3 mt-2 mb-2">
                            <div class="row">
                                <div class="col-12">
                                        <span>
                                            {{ order.order_num }}
                                        </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <span>
                                            {{ order.order_created }}
                                        </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <span>
                                            {{ order.branch_name }}
                                        </span>
                                </div>
                            </div>
                            <div class="row" v-if="role === 1">
                                <div class="col-12">
                                        <span>
                                            {{ order.manager_name }}
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 mt-2 mb-2">
                            <div class="row">
                                <div class="col-12">
                                        <span class="help-span">
                                               Статус :
                                        </span>
                                    <span>
                                            {{ order.status }}
                                        </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <span class="help-span">
                                               Статус закрытия :
                                        </span>
                                    <span>
                                            {{ order.closing_status }}
                                        </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <span class="help-span">
                                               Законтрактован :
                                        </span>
                                    <span>
                                            {{ order.is_contracted }}
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-5 mt-2 mb-2 flex-beet">
                            <div class="row">
                                <div class="col-12">
                                        <span class="help-span">
                                               Оферта :
                                        </span>
                                    <span>
                                            {{ order.offer }}
                                        </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <span class="help-span">
                                               Оферта прочитана:
                                        </span>
                                    <span>
                                            {{ order.offer_read }}
                                        </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <span class="help-span">
                                               Опросник :
                                        </span>
                                    <span>
                                            {{ order.questions_complete }}
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 mt-2 mb-2 flex-cent">
                            <div class="row">
                                <div class="col-12">
                                        <span>
                                            {{ getScore(order.total) }} руб.
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
    <div class="pagination__wrapper">
        <ul class="pagination">
            <li>
                <button class="prev" @click="prevPage" :disabled="pageNumber===0">&#10094;</button>
            </li>
            <template v-for="(number,key) in allPages">
                <li @click="key !== pageNumber ? openPage(key) : ''"
                    v-if="key === 0 || number === allPages || (pageNumber + 7 >= key && pageNumber - 7 <= key)">
                    <button
                        v-bind:class="{'active':pageNumber === key,'side_numbers':key === 0 || number === allPages}">
                        {{ number }}
                    </button>
                </li>
            </template>
            <li>
                <button class="next" @click="nextPage" :disabled="pageNumber">&#10095;</button>
            </li>
        </ul>
    </div>
</div>
