<template src="./templates/partners.html"></template>

<script>
    import Multiselect from '@vueform/multiselect';
    import '../../layouts/default/styles/partners.scss';
    import Datepicker from 'vue3-datepicker';

    import {
        getManagers,
        getFilters,
        getPartnersAndOrders,
        getCountPartners,
        getRole
    } from '@api/orders';

    import Input from '@f/Input';
    import FormCheckbox from '@f/Check';

    export default {
        name: 'Partners',
        components: {
            Multiselect,
            Input,
            Datepicker,
            FormCheckbox
        },
        props: {
            size: {
                type: Number,
                required: false,
                default: 100
            },
        },
        data() {
            return {
                date_from: null,
                date_to: null,
                orders: null,
                partners: null,
                branches: [],
                managers: [],
                role: 0,
                showOrdersArray: [],
                pageNumber: 0,
                allPages: 0,
                partnersList: [],
                countPartners: 0,
                statusFilters: {statuses: [], closeStatuses: []},
                statuses: [],
                closeStatuses: [],
                selectionValues: {
                    partner_name: null,
                    partner_code: null,
                    area: null,
                    branch_codes: null,
                    manager_id: null,
                    offer: null,
                    contract: null,
                    quest: null,
                    date_from: null,
                    date_to: null,
                    close_statuses: null,
                    statuses: null,
                    offer_read: null,
                },
            };
        },
        watch: {
            date_from(newDate) {
                const date = new Date(newDate);
                this.selectDateFrom(date.toLocaleDateString());
            },
            date_to(newDate) {
                const date = new Date(newDate);
                console.log(newDate);
                this.selectDateTo(date.toLocaleDateString());
            },
        },
        async created() {
            await this.compileData(this.pageNumber * 100);
            this.branches = this.$store.state.Catalogs.branches;
            this.managers = await getManagers();
            this.statusFilters = await getFilters();
            this.role = await getRole();
        },
        methods: {
            async nextPage() {
                this.pageNumber++;
                await this.compileData(this.pageNumber * 100);
            },
            async prevPage() {
                this.pageNumber--;
                await this.compileData(this.pageNumber * 100);
            },
            async openPage(key) {
                this.pageNumber = key;
                await this.compileData(this.pageNumber * 100);
            },
            redirectToOrders(partner_id) {
                this.$router.push({name: 'Orders', query: {partnerId: partner_id}});
            },
            async compileData(offset) {
                this.partnersList = await getPartnersAndOrders(offset, this.selectionValues);
                this.countPartners = await getCountPartners(this.selectionValues);
                this.allPages = Math.ceil(this.countPartners / 100);
            },
            async selectPartnerName(e) {
                this.selectionValues.partner_name = e.target.value.length > 3 ? e.target.value : null;
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectPartnerCode(e) {
                this.selectionValues.partner_code = e.target.value.length > 3 ? e.target.value : null;
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectOrdersCount(e) {
                this.selectionValues.count_orders = e.target.value.length > 0 ? e.target.value : null;
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectArea(e) {
                this.selectionValues.area = e.target.value.length > 3 ? e.target.value : null;
                this.pageNumber = 0;
                await this.compileData(0);
            },
            showHideOrders(ind) {
                if (this.showOrdersArray.indexOf(ind) !== -1) {
                    this.showOrdersArray.splice(this.showOrdersArray.indexOf(ind), 1);
                } else {
                    this.showOrdersArray.push(ind);
                }
            },
             getScore(val) {
                const int = parseInt(val, 10);
                return new Intl.NumberFormat('ru-RU').format(int);
            },
            redirectToResult(question) {
                if (question.version > 0) {
                    this.$router.push({path: '/questionnaire/edit', query: {result_code: question.result_code}});
                } else {
                    window.location = `https://lbr.ru/crm/questionnaire?edit=${question.result_code}`;
                }
            },
            async selectDateFrom(date) {
                this.selectionValues.date_from = date.length > 0 ? date : null;
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectDateTo(date) {
                this.selectionValues.date_to = date.length > 0 ? date : null;
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectOffer(e) {
                this.selectionValues.offer = parseInt(e.target.value, 2);
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectOfferRead(e) {
                this.selectionValues.offer_read = parseInt(e.target.value, 2);
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectContract(e) {
                this.selectionValues.contract = parseInt(e.target.value, 2);
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectQuest(e) {
                this.selectionValues.quest = parseInt(e.target.value, 2);
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectBranch(branch) {
                this.selectionValues.branch_codes = branch;
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectCloseStatus(status) {
                console.log(status);
                this.selectionValues.close_status = status.id;
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectStatus(status) {
                this.selectionValues.status = status.id;
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async clearStatus(status) {
                console.log(status, 'clearStatus');
                this.selectionValues.status = status.id;
                this.pageNumber = 0;
                await this.compileData(0);
            },
            async selectManager(managerId) {
                this.selectionValues.manager_id = managerId;
                this.pageNumber = 0;
                await this.compileData(0);
            },
        }
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
