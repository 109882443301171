import api from '../tools/api';

export const getOrders = (page_number, select_values) => api.request(
    'orders',
    'get-orders',
    {page_number, select_values}
);

export const getBranches = () => api.request(
        'orders',
        'get-branches'
);

export const getManagers = () => api.request(
        'orders',
        'get-managers'
);

export const getFilters = () => api.request(
        'orders',
        'get-filters'
);

export const getPartners = () => api.request(
        'orders',
        'get-partners'
);

export const getCountOrders = (select_values) => api.request(
        'orders',
        'get-count-orders',
        {select_values}
);

export const getPartnersAndOrders = (page_number, select_values) => api.request(
    'orders',
    'get-partners-and-orders',
    {page_number, select_values}
);

export const getCountPartners = (select_values) => api.request(
    'orders',
    'get-count-partners',
    {select_values}
);

export const getRole = () => api.request(
    'orders',
    'get-role'
);

